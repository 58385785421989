/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { genEmailLink, TH_EMAIL_ADDRESS, TH_SCHEDULE_A_CALL } from '../../constants/externalURLs';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import GlobalPresence from '../../components/GlobalPresence';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import Partner from '../../components/Partner';
import Button from '../../components/Button';
import CallToActionImage from '../../assets/call-to-action.png';
import { partnersData } from './partnersData';
import s from './Contact.module.scss';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/contact-us.png',
  ogTitle: 'Contact | Tech Holding',
  ogDesc:
    "Connect with TechHolding for inquiries, collaborations, or consultations. Reach out today and let's start a conversation about how we can help you achieve your goals.",
  ogPath: '/contact',
};

const updatedSeo = getSeoConfig(seoParams);

const Contact = ({ companyQuote }) => (
  <Layout
    currentPage="/contact"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <div>
      <PageHeader>
        <h2>Ready to innovate?</h2>
        <p className={s.paragraph}>
          Leave us a message and one of our experts will get back to you shortly or You can also
          reach us at&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={genEmailLink(TH_EMAIL_ADDRESS)}>
            {TH_EMAIL_ADDRESS}
          </a>
        </p>
      </PageHeader>
      <div className={s.contactForm} data-aos="fade-up" data-aos-delay="200">
        <div className={s.container}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div id="mc_embed_shell" class="contact-form">
              <div id="mc_embed_signup">
                  <form action="https://techholding.us18.list-manage.com/subscribe/post?u=12eacc3d78ee8cee150f4a09c&amp;id=6d2ac1e5cd&amp;f_id=006316e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                    <div id="mc_embed_signup_scroll">
                        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                        <div class="mc-field-group">
                            <input type="text" name="FNAME" class="required text form-input" id="mce-FNAME" value="" required="" placeholder="Enter First Name">
                            <label for="mce-FNAME" class="form-label">First Name <span class="asterisk">*</span></label>
                        </div>
                        <div class="mc-field-group">
                            <input type="text" name="LNAME" class="required text form-input" id="mce-LNAME" value="" required="" placeholder="Enter Last Name">
                            <label for="mce-LNAME" class="form-label">Last Name <span class="asterisk">*</span></label>
                        </div>
                        <div class="mc-field-group">
                            <input type="text" name="PHONE" class="REQ_CSS form-input" id="mce-PHONE" value="" required="" placeholder="Enter Phone Number">
                            <label for="mce-PHONE" class="form-label">Phone Number <span class="asterisk">*</span></label>
                        </div>
                        <div class="mc-field-group">
                            <input type="email" name="EMAIL" class="required email form-input" id="mce-EMAIL" required="" value="" placeholder="Enter Email">
                            <label for="mce-EMAIL" class="form-label">Email <span class="asterisk">*</span></label>
                        </div>
                        <div class="mc-field-group">
                            <input type="text" name="COMPNAME" class="required text form-input" id="mce-COMPNAME" required="" value="" placeholder="Enter Company Name">
                            <label for="mce-COMPNAME" class="form-label">Company Name <span class="asterisk">*</span></label>
                        </div>
                        <div class="mc-field-group">
                            <input type="text" name="MESSAGE" class="required text form-input" id="mce-MESSAGE" value="" required="" placeholder="Enter Message">
                            <label for="mce-MESSAGE" class="form-label">What can we help you with? <span class="asterisk">*</span></label>
                        </div>
                        <div hidden=""><input type="hidden" name="tags" value="2104188"></div>
                        <div id="mce-responses" class="clear">
                          <div class="response" id="mce-error-response" style="display: none;"></div>
                          <div class="response" id="mce-success-response" style="display: none;"></div>
                        </div>
                        <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_12eacc3d78ee8cee150f4a09c_6d2ac1e5cd" tabindex="-1" value=""></div>
                        <div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Send Message"></div>
                    </div>
                  </form>
              </div>
              <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[4]='PHONE';ftypes[4]='phone';fnames[0]='EMAIL';ftypes[0]='email';fnames[6]='COMPNAME';ftypes[6]='text';fnames[8]='MESSAGE';ftypes[8]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='MEETINGTYP';ftypes[7]='radio';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
            </div>`,
            }}
          />
        </div>
      </div>
      <GlobalPresence />
      <div className={s.callToAction}>
        <div className={s.container}>
          <div className={s.text}>
            <h2>Schedule a free consultation to discuss your specific business needs.</h2>
            <a target="_blank" rel="noopener noreferrer" href={TH_SCHEDULE_A_CALL}>
              <Button>Schedule a Call</Button>
            </a>
          </div>
          <div className={s.image}>
            <img src={CallToActionImage} alt="" />
          </div>
        </div>
      </div>
      <Partner partnersImages={partnersData} />
    </div>
  </Layout>
);

Contact.propTypes = propTypes;
export default Contact;
